import React, { useContext, useEffect, useState } from 'react'
import { ScriptContext } from '../../../../../../context/ScriptsContext'
import { removeFiles } from '../SelectProcess/SelectProcessFunctions'
import { DataContext } from '../../../../../../context/DataContext'
import { Modal } from '../../../../../../components/Modal/Modal'
import { useNavigate } from 'react-router-dom'
import { useModal } from '../../../../../../hooks/useModal'
import { handleSubmitNewInstance, handleUploadFiles } from './UpdateProcessFunctions'
import iconUpload from '../../../../../../assets/icons/icon_subir_archivos.svg'
import iconRemove from '../../../../../../assets/icons/icon_remove.svg'
import './UpdateProcess.css'

export const UpdateProcess = ({ isActive, demandInfo }) => {
  const navigate = useNavigate()
  const [showModal, handleShow] = useModal()
  const { demandId, requirementId, instanceHistory } = demandInfo
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, infoSaved } = dataContext
  const { employeeId, idToken, instanceUpdatesList } = dataState.lawyer

  const [instanceUpdatesListFormatted, setInstanceUpdatesListFormatted] = useState([])
  const [filesUploaded, setFilesUploaded] = useState([])
  const [instanceData, setInstanceData] = useState({
    instanceUpdateId: '',
    createDt: ''
  })

  useEffect(() => {
    const requirementInstancesList = instanceUpdatesList?.filter((item) => item.requirementId === requirementId)
    const listFormatted = requirementInstancesList.filter((instance) => !instanceHistory.some((item) => item.instanceHistoryId === instance.instanceUpdateId))
    setInstanceUpdatesListFormatted(listFormatted.splice(1))
  }, [demandInfo])

  if (!isActive) return null
  return (
    <section id='UpdateProcess'>
      <div className="UpdateProcessTSide">
        <div className="UpdateProcessTSideLSide">
            <h2 className='UpdateProcessTitle'>Actualización</h2>
            <div className='UpdateProcessListBox'>
              {instanceUpdatesListFormatted?.map((update) => (
                <div key={update.instanceUpdateId} className='UpdateProcessInputBox'>
                  <input
                    type="radio"
                    name="group1"
                    value={update.instanceUpdateId}
                    onClick={() => setInstanceData({ ...instanceData, instanceUpdateId: update.instanceUpdateId.toString() })}
                    className='UpdateProcessCheck'
                  />
                  <span className='UpdateProcessBoxText'>
                    {update.instanceUpdateName}
                  </span>
                </div>
              ))}
            </div>
        </div>
        <div className="UpdateProcessTSideRSide">
          <h2 className='UpdateProcessTitle'>Subir soportes para la actualización</h2>
          <div className='UpdateProcessBoxFiles'>
            {filesUploaded.length
              ? <div className='SelectProcessBoxFiles'>
                  {filesUploaded.map((file, index) => (
                    <div className='UpdateProcessBoxFile' key={index}>
                      <p className='UpdateProcessBoxFileName'>{`soporte ${index + 1}`}</p>
                      <button
                        className='UpdateProcessBoxFileRemoveBtn'
                        onClick={() => removeFiles(file.name, filesUploaded, setFilesUploaded)}
                      >
                        <img
                          src={iconRemove}
                          alt={scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.altImageTxt1 : ''}
                        />
                      </button>
                    </div>
                  ))}
                </div>
              : <h2 className='SelectProcessBoxTitle NoAnnexes'>
                  {scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.noAnnexesUploaded : ''}
                </h2>
            }
            <div className="UpdateProcessBoxUpdateDate">
              <p className='UpdateProcessBoxText'>Fecha de actualización</p>
              <input
                className='UpdateProcessBoxText'
                type="date"
                onChange={(e) => setInstanceData({ ...instanceData, createDt: e.target.value })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="UpdateProcessBSide">
        <input
          type="file"
          id='annexesFilesInput'
          multiple
          onChange={(event) => handleUploadFiles(event, filesUploaded, setFilesUploaded)}
          accept='.jpg, .jpeg, .png, .pdf'
        />
        <label htmlFor='annexesFilesInput' className='SelectProcessUploadButton'>
          {scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.uploadAnnexes : ''}
          <img
            src={iconUpload}
            className='SelectProcessUploadButtonImg'
            alt={scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.altImageTxt2 : ''}
          />
        </label>
        <button
          className='SelectProcessSendButton'
          onClick={() =>
            handleSubmitNewInstance({
              instanceData,
              dataIds: { employeeId, demandId },
              filesUploaded,
              dataContext,
              idToken,
              handleShow
            })}
          disabled={!filesUploaded.length || Object.values(instanceData).some((item) => item === '')}
        >
          Enviar
        </button>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{'¡Nueva instancia creada correctamente!'}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-orangeBtn' onClick={() => navigate('../SeguimientoCasos/SeguimientoCasosDetalle', { state: infoSaved })}>
            {scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.accept : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
